import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTimesCircle,
  faList,
  faTrash,
  faBookBookmark,
} from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/Layout";
import AllergenTags from "../components/AllergenTags";

import {
  getMenuItem,
  getModifiers,
  getModifierOptions,
} from "../helpers/toastApiCalls";
import { getEntriesByType } from "../helpers/contentfulApiCalls";

import defaultImage from "../../static/images/4m-default-food-image.png";

import * as styles from "../styles/Main.module.css";
import * as menuStyles from "../styles/Menus.module.css";
import * as foodlineStyles from "../styles/Foodline.module.css";
import { getFromLocalStorage, modifySavedItems } from "../helpers/savedItems";
import SideDrawer from "../components/SideDrawer";

const Menu = ({ location, pageContext }) => {
  const { slug, guid, name, availability, menuGroups } = pageContext;

  const [loadingContentful, setLoadingContentful] = useState(true);
  const [contentfulFoodLine, setContentfulFoodLine] = useState(null);
  const [chefPicksItems, setChefsPicksItems] = useState(null);
  const [environmentImage, setEnvironmentImage] = useState("");
  const [activeGroup, setActiveGroup] = useState(null);
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [foodItem, setFoodItem] = useState(null);
  const [modifiers, setModifiers] = useState(null);
  const [pairs, setPairs] = useState(null);
  const [offset, setOffset] = useState(0);
  const [ylocation, setYLocation] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [savedItems, setSavedItems] = useState([]);
  const [isFoodItemSaved, setIsFoodItemSaved] = useState(false);

  const url = location?.href ? location?.href : "";

  useEffect(() => {
    const search = location?.search.replace("?", "");

    let navItem = {};

    if (search !== "" && savedItems && savedItems.length > 0) {
      navItem = savedItems.find((item) => {
        return item.guid === search;
      });

      if (navItem) {
        setSelectedFoodItem(navItem);
        setOffset(ylocation);
        setTimeout(() => {
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth",
          });
        }, 1000);
      }
    }
  }, [location, savedItems]);

  useEffect(() => {
    const localStorage = getFromLocalStorage();

    if (localStorage.ok) {
      setSavedItems(localStorage.data);
    }
  }, []);

  useEffect(() => {
    const handleTouch = (event) => {
      if (event.type === "touchstart") {
        const touch = event?.touches[0] || event?.changedTouches[0];
        setYLocation(touch?.pageY);
      }
    };

    const handleMouseMove = (event) => {
      setYLocation(event.pageY);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchstart", handleTouch);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchstart", handleTouch);
    };
  }, []);

  useEffect(() => {
    const getChefsPicksItems = async (chefsPicks) => {
      const picksArray = [];

      for (let i = 0; i < chefsPicks.length; i++) {
        if (chefsPicks[i].length === 36) {
          const item = await getMenuItem(chefsPicks[i]);
          if (item) {
            picksArray.push({ ...item, key: item.guid });
          }
        }
      }
      setChefsPicksItems(picksArray);
      setLoadingContentful(false);
    };

    const entriesByType = async () => {
      try {
        let foodLine = await getEntriesByType("foodLine");

        foodLine = foodLine.filter((line) => {
          return line.fields.menuGuid === guid;
        });
        setContentfulFoodLine(foodLine);
        setEnvironmentImage(
          foodLine[0]?.fields?.environmentImage?.fields?.file?.url
            ? foodLine[0].fields.environmentImage.fields.file.url
            : ""
        );
        if (foodLine[0]?.fields?.chefsPicks) {
          getChefsPicksItems(foodLine[0].fields.chefsPicks);
        } else {
          setLoadingContentful(false);
        }
      } catch (error) {
        console.log("Error getting Food Lines", error);
      }
    };

    entriesByType();
  }, []);

  useEffect(() => {
    const getPairsWellWith = async (foodItem) => {
      const pairsArray = [];
      const allMenuItems = await getEntriesByType("menuItem");
      const contentfulFoodItem = allMenuItems.find(
        (menuItem) => foodItem.guid === menuItem?.fields?.guid.trim()
      );

      const pairs = contentfulFoodItem?.fields?.pairsWellWith || [];

      for (let i = 0; i < pairs.length; i++) {
        if (pairs[i].length === 36) {
          const toastItem = await getMenuItem(pairs[i]);
          if (toastItem) {
            pairsArray.push({
              ...toastItem,
              key: toastItem.guid,
            });
          }
        }
      }
      setPairs(pairsArray);
    };

    const getSelectedFoodItem = async () => {
      const foodItem = await getMenuItem(selectedFoodItem.guid);
      const modifiers = await getModifiers();
      const modifierOptions = await getModifierOptions();

      let modifierData = foodItem?.modifierGroupReferences.map((id) => {
        const item = modifiers[id];
        item.modifierOptionsData = item.modifierOptionReferences.map(
          (optId) => modifierOptions[optId]
        );
        return item;
      });
      setModifiers(modifierData);
      setFoodItem(foodItem);
      getPairsWellWith(foodItem);
      // window.scrollTo(0, 0);
    };

    if (selectedFoodItem) {
      getSelectedFoodItem();
    }
  }, [selectedFoodItem]);

  useEffect(() => {
    const updateIsFoodItemSaved = () => {
      if (savedItems && savedItems.length > 0) {
        const isFoundIndex = savedItems.find(
          (findItem) => findItem.guid === foodItem.guid
        );

        return isFoundIndex;
      } else {
        return false;
      }
    };

    if (foodItem) {
      setIsFoodItemSaved(updateIsFoodItemSaved());
    }
  }, [savedItems, foodItem]);

  const MenuItemDetails = ({ item, middle, onClick, onKeyDown }) => {
    return (
      <div>
        <div
          className={foodlineStyles.foodItemName}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          <h6>{item.name}</h6>

          <p className={foodlineStyles.price}>
            {item.price ? `$${Number(item.price).toFixed(2)}` : "See options"}
          </p>
        </div>
        {middle && middle}
        <p onClick={onClick} onKeyDown={onKeyDown}>
          {item.description}
        </p>
      </div>
    );
  };

  const OpenDrawerButton = () => {
    let itemsText;

    if (savedItems?.length === 0) {
      itemsText = "";
    } else if (savedItems?.length <= 9) {
      itemsText = savedItems?.length;
    } else if (savedItems?.length > 9) {
      itemsText = "9+";
    } else {
      itemsText = 0;
    }

    if (savedItems && savedItems.length > 0) {
      return (
        <div
          onClick={() => {
            setSidebarOpen(true);
          }}
          className={menuStyles.drawerButton}
          title="Save for Later"
        >
          <FontAwesomeIcon icon={faBookBookmark} size="xl" />
          {itemsText && (
            <div className={menuStyles.items}>
              <span>{itemsText}</span>
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const SaveOrRemoveButton = ({ item }) => {
    const foundSavedItem = savedItems?.some((savedItem) => {
      return savedItem.guid === item.guid;
    });

    return foundSavedItem ? (
      <div
        onClick={() => {
          const newSavedItems = modifySavedItems(item, "REMOVE");
          if (newSavedItems !== null) {
            setSavedItems(newSavedItems);
          }

          setIsFoodItemSaved(false);
        }}
        className={menuStyles.modifyItem}
      >
        <FontAwesomeIcon icon={faTrash} size="lg" /> Remove from Saved
      </div>
    ) : (
      <div
        onClick={() => {
          const newSavedItems = modifySavedItems(item, "SAVE");
          if (newSavedItems !== null) {
            setSavedItems(newSavedItems);
          }

          setSidebarOpen(true);
          setIsFoodItemSaved(true);
        }}
        className={menuStyles.modifyItem}
      >
        <FontAwesomeIcon icon={faBookBookmark} size="lg" /> Save for Later
      </div>
    );
  };

  return (
    <Layout url={url} name={name} slug={slug}>
      <SideDrawer
        isOpen={sidebarOpen}
        setOpen={setSidebarOpen}
        savedItems={savedItems}
        setSavedItems={setSavedItems}
      >
        <div className={styles.main}>
          <div className={styles.content}>
            {selectedFoodItem && foodItem ? (
              <div className={styles.main}>
                <div className={styles.content}>
                  <div className={foodlineStyles.foodItemHeader}>
                    <div
                      className={foodlineStyles.foodItemClose}
                      onClick={() => {
                        navigate(`/${slug}`);
                        setSelectedFoodItem(null);
                        setFoodItem(null);

                        setTimeout(() => {
                          const wHeight = window.innerHeight;
                          window.scrollTo({
                            left: 0,
                            top: offset - wHeight / 2,
                            behavior: "smooth",
                          });
                        }, 100);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                      <p>Back to {selectedFoodItem.menuName}</p>
                    </div>
                    <div className={foodlineStyles.itemNav}>
                      <SaveOrRemoveButton item={foodItem} />
                      <OpenDrawerButton />
                    </div>
                  </div>
                  <div>
                    <div className={foodlineStyles.mainContent}>
                      {foodItem.menuName !== "Bar 19" &&
                      foodItem.menuName !== "Happy Hour" ? (
                        <img
                          src={foodItem.image || defaultImage}
                          alt={foodItem.name}
                        />
                      ) : (
                        <img
                          src={foodItem.menuImage || defaultImage}
                          alt={foodItem.name}
                        />
                      )}
                      <div>
                        <div className={foodlineStyles.foodItemName}>
                          <h3>{foodItem.name}</h3>

                          {foodItem.price > 0 && (
                            <p className={foodlineStyles.price}>
                              ${Number(foodItem.price).toFixed(2)}
                            </p>
                          )}
                        </div>
                        {foodItem.inventoryStatus !== "IN_STOCK" && (
                          <div className={foodlineStyles.foodItemUnavailable}>
                            <p>Currently Unavailable</p>
                          </div>
                        )}
                        <p>{foodItem.description}</p>

                        {foodItem.itemTags && (
                          <div>
                            <AllergenTags data={foodItem.itemTags} />
                          </div>
                        )}
                      </div>
                    </div>

                    {modifiers && (
                      <div className={foodlineStyles.modifiers}>
                        {modifiers.map((modifierOption) => {
                          return (
                            <div key={modifierOption.guid}>
                              <h6>{modifierOption.name}</h6>
                              {modifierOption.modifierOptionsData.map(
                                (modifier) => {
                                  return (
                                    <div
                                      className={foodlineStyles.modifier}
                                      key={modifier.guid}
                                    >
                                      <p>{modifier.name}</p>
                                      <p>
                                        ${Number(modifier.price).toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {pairs && pairs.length > 0 && (
                      <div>
                        <h5>Pairs Well With:</h5>
                        <div className={foodlineStyles.chefsPicks}>
                          {pairs.map((pair) => {
                            if (
                              pair.menuName !== "Bar 19" &&
                              pair.menuName !== "Happy Hour"
                            ) {
                              return (
                                <div
                                  key={pair.guid}
                                  tabIndex="0"
                                  className={foodlineStyles.chefPick}
                                  onClick={() => {
                                    setSelectedFoodItem(pair);
                                    setOffset(ylocation);
                                    setTimeout(() => {
                                      window.scrollTo({
                                        left: 0,
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }, 1000);
                                  }}
                                  onKeyDown={() => {
                                    setSelectedFoodItem(pair);
                                  }}
                                  role="button"
                                >
                                  <img
                                    src={pair.image || defaultImage}
                                    alt={pair.name}
                                  />

                                  <h6>{pair.name}</h6>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={pair.guid}
                                  className={`${foodlineStyles.chefPick} ${foodlineStyles.noClick}`}
                                >
                                  <img
                                    src={pair.menuImage || defaultImage}
                                    alt={pair.name}
                                  />
                                  <h6>{pair.name}</h6>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <SideDrawer
                isOpen={sidebarOpen}
                setOpen={setSidebarOpen}
                savedItems={savedItems}
                setSavedItems={setSavedItems}
              >
                <div className={menuStyles.menusWrapper}>
                  <div className={menuStyles.menus}>
                    <div className={menuStyles.header}>
                      <Link
                        to={
                          url !== "" && !url.includes("digitalmenu")
                            ? "https://experience4m.com"
                            : "/"
                        }
                      >
                        <img
                          src="/logos/4M-Logo-VENUE_LOGO-Blue-Blk.png"
                          alt=""
                          width="200"
                        />
                      </Link>

                      {url !== "" && !url.includes("digitalmenu") ? (
                        <div>
                          <a
                            href="https://www.toasttab.com/venue-by-4m-1919-s-industrial-hwy/v3"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Order 2go
                          </a>
                          <a
                            href="https://www.exploretock.com/venue-annarbor"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Reservations
                          </a>
                          {savedItems && savedItems.length > 0 && (
                            <OpenDrawerButton />
                          )}
                        </div>
                      ) : (
                        <>
                          {savedItems && savedItems.length > 0 && (
                            <OpenDrawerButton />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className={foodlineStyles.hero}>
                    <div className={foodlineStyles.heroTitle}>
                      <Link
                        style={{ marginLeft: "4px" }}
                        to="/"
                        className={menuStyles.backBtn}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} size="xl" /> All
                        Menus
                      </Link>
                      <h1>{name}</h1>
                      <div>&nbsp;</div>
                    </div>
                    <img
                      src={environmentImage || defaultImage}
                      width=""
                      alt={name}
                    />
                    {contentfulFoodLine &&
                      contentfulFoodLine[0]?.fields?.bannerText && (
                        <p className={foodlineStyles.bannerText}>
                          {contentfulFoodLine[0].fields.bannerText}
                        </p>
                      )}
                    <div className={foodlineStyles.heroContent}>
                      <div>
                        {contentfulFoodLine &&
                          contentfulFoodLine[0]?.fields?.marketing && (
                            <>
                              <h6>{contentfulFoodLine[0].fields.marketing}</h6>
                            </>
                          )}
                      </div>
                      <div>
                        {contentfulFoodLine &&
                          contentfulFoodLine[0]?.fields?.description && (
                            <p className={foodlineStyles.description}>
                              {contentfulFoodLine[0].fields.description}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  {chefPicksItems && (
                    <div>
                      <h5>Chefs Picks:</h5>
                      <div className={foodlineStyles.chefsPicks}>
                        {chefPicksItems.map((chefPick) => {
                          if (
                            chefPick.menuName !== "Bar 19" &&
                            chefPick.menuName !== "Happy Hour"
                          ) {
                            return (
                              <div
                                tabIndex="0"
                                key={chefPick.guid}
                                className={foodlineStyles.chefPick}
                                onClick={() => {
                                  setSelectedFoodItem(chefPick);
                                  setOffset(ylocation);
                                  setTimeout(() => {
                                    window.scrollTo({
                                      left: 0,
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                  }, 1000);
                                }}
                                onKeyDown={() => {
                                  setSelectedFoodItem(chefPick);
                                }}
                                role="button"
                              >
                                <img
                                  src={chefPick.image || defaultImage}
                                  alt={chefPick.name}
                                />

                                <h6>{chefPick.name}</h6>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={chefPick.guid}
                                className={`${foodlineStyles.chefPick} ${foodlineStyles.noClick}`}
                              >
                                <img
                                  src={chefPick.menuImage || defaultImage}
                                  alt={chefPick.name}
                                />
                                <h6>{chefPick.name}</h6>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                  {menuGroups && (
                    <div className={foodlineStyles.groups}>
                      {menuGroups.map((group) => {
                        return (
                          <div
                            onClick={() =>
                              setActiveGroup(
                                group.guid === activeGroup ? null : group.guid
                              )
                            }
                            onKeyDown={() =>
                              setActiveGroup(
                                group.guid === activeGroup ? null : group.guid
                              )
                            }
                            tabIndex="0"
                            className={`${foodlineStyles.group} ${
                              activeGroup === group.guid &&
                              foodlineStyles.active
                            }`}
                            key={group.guid}
                            role="button"
                          >
                            {group.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className={foodlineStyles.menuGroups}>
                    <ul>
                      {menuGroups.map((group) => {
                        return (
                          <li
                            key={group.guid}
                            className={`${foodlineStyles.menuGroup} ${
                              activeGroup && activeGroup !== group.guid
                                ? foodlineStyles.hide
                                : null
                            }`}
                          >
                            <h4>{group.name}</h4>
                            <div className={foodlineStyles.menuItems}>
                              {group.menuItems.map((item) => {
                                if (
                                  name !== "Bar 19" &&
                                  name !== "Happy Hour"
                                ) {
                                  return (
                                    <div
                                      id="test"
                                      tabIndex="0"
                                      className={foodlineStyles.menuItem}
                                      key={item.guid}
                                      role="button"
                                    >
                                      <img
                                        src={item.image || defaultImage}
                                        alt={item.name}
                                        loading="lazy"
                                        onClick={() => {
                                          setSelectedFoodItem(item);
                                          setOffset(ylocation);
                                          setTimeout(() => {
                                            window.scrollTo({
                                              left: 0,
                                              top: 0,
                                              behavior: "smooth",
                                            });
                                          }, 1000);
                                        }}
                                        onKeyDown={() => {
                                          setSelectedFoodItem(item);
                                        }}
                                      />

                                      <MenuItemDetails
                                        item={item}
                                        middle={
                                          <div
                                            className={
                                              menuStyles.menuItemWrapper
                                            }
                                          >
                                            <SaveOrRemoveButton item={item} />
                                          </div>
                                        }
                                        onClick={() => {
                                          setSelectedFoodItem(item);
                                          setOffset(location);
                                          setTimeout(() => {
                                            window.scrollTo({
                                              left: 0,
                                              top: 0,
                                              behavior: "smooth",
                                            });
                                          }, 1000);
                                        }}
                                        onKeyDown={() => {
                                          setSelectedFoodItem(item);
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className={`${foodlineStyles.menuItem} ${foodlineStyles.noClick}`}
                                      key={item.guid}
                                    >
                                      <MenuItemDetails
                                        item={item}
                                        middle={
                                          <div
                                            className={
                                              menuStyles.menuItemWrapper
                                            }
                                          >
                                            <SaveOrRemoveButton item={item} />
                                          </div>
                                        }
                                      />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </SideDrawer>
            )}
          </div>
        </div>
      </SideDrawer>
    </Layout>
  );
};

export default Menu;
