import React from "react";
import { ReactSVG } from "react-svg";

import * as allergenStyles from "../styles/Allergens.module.css";

const ICON_LIST = {
  Shellfish: "4-SHELLFISH",
  Dairy: "DAIRY",
  Vegetarian: "VEGETARIAN",
  Vegan: "VEGAN",
  Egg: "EGGS",
  Peanuts: "PEANUT",
  "Sesame Seeds": "SESAME",
  Soy: "SOY",
  "Tree Nut": "TREENUT",
};

const AllergenTags = (props) => {
  const { data } = props;

  return (
    <div className={allergenStyles.allergens}>
      {data.map((item, index) => {
        if (ICON_LIST[item.name]) {
          return (
            <div key={`${index}-${item.guid}`}>
              <ReactSVG src={`/icons/${ICON_LIST[item.name]}.svg`} />
              <p>{item.name}</p>
            </div>
          );
        } else {
          return false;
        }
      })}
    </div>
  );
};

export default AllergenTags;
